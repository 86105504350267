<template>
    <div class="polish-index-page">
        <div class="polish-wapper">
            <div class="polish-main">
                <div class="polish-content">
                    <div class="polish-tabs">
                        <div class="item" :disabled="formDisabled" :class="{'active' : polishType === 'text'}" @click="polishType = 'text'"><svg-icon name="ib-text"></svg-icon>{{$t('tools.tools_polishIndex_page_tips_1')}}</div>
                        <div class="item" :disabled="formDisabled" :class="{'active' : polishType === 'document'}" @click="polishType = 'document'; form.lang = 'en'; initFileHistoryList()"><svg-icon name="ib-document"></svg-icon>{{$t('tools.tools_polishIndex_page_tips_2')}}</div>
                    </div>
                    <div class="wapper-block">
                        <template v-if="polishType === 'text'">
                            <div class="bg-white radius-16 padding-20">
                                <div class="flex flex-justify-between">
                                    <div class="fonts-15 text-weight-600">{{$t('tools.tools_polishIndex_page_tips_3')}}</div>
                                    <div class="fonts-15 cursor-pointer" :disabled="formDisabled" @click="clearTextInput" v-if="form.text.length > 0"><svg-icon name="ib-close"></svg-icon></div>
                                </div>
                                <el-input v-model="form.text" @input="textInputEvent" type="textarea" @keydown.enter.native="enterKeyEvent" :placeholder="$t('tools.tools_polishIndex_page_input_placeholder_1')" :disabled="formDisabled" resize="none" class="text-input" rows="7" :maxlength="maxlength"></el-input>
                                <div class="flex flex-align-center flex-justify-end margin-t-10">
                                    <!-- <div class="fonts-12 color-999 text-weight-200 text-italic">Powered by ChatGPT API</div> -->
                                    <div class="flex flex-align-center">
                                        <div class="fonts-12 margin-r-10">{{$t('tools.tools_polishIndex_page_tips_4', {size1: form.text.length, size2: maxlength})}}</div>
                                        <el-tooltip :content="`${btnName} Ctrl / ⌘ + Enter`" placement="top">
                                            <el-button type="primary" round class="send-btn" :loading="formDisabled" @click="createPolishTask">
                                                <svg-icon name="ib-send" v-if="!formDisabled"></svg-icon>
                                            </el-button>
                                        </el-tooltip>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="polishType === 'document'">
                            <div class="bg-white radius-16" style="height: 253.29px;">
                                <div class="polish-document-wrapper" v-if="docs.length === 0">
                                    <svg-icon name="ib-upload-o" style="font-size: 50px;"></svg-icon>
                                    <div class="text-center fonts-14 color-666666 margin-y-20 line-height-1.5">{{$t('tools.tools_polishIndex_page_tips_5')}}<br>{{$t('tools.tools_polishIndex_page_tips_5_1')}}</div>
                                    <upload-oss-button ref="uploadNode" :title="$t('tools.tools_polishIndex_page_tips_6')" btn-size="medium" :size="20" accept=".doc,.docx,.ppt,.pptx" @success="uploadFileSuccess"></upload-oss-button>
                                </div>
                                <div class="polish-document-wrapper" v-else>
                                    <div class="close-btn" @click="closeUploadDocumentBtnEvent">
                                        <svg-icon name="ib-close"></svg-icon>
                                    </div>
                                    <div class="file-type">
                                        <svg-icon :name="`ic-${config.FILE_TYPE_MATCH['.'+fileType].icon}`"></svg-icon>
                                    </div>
                                    <div class="margin-t-15 fonts-15 text-center text-omission" style="width: 90%;">{{docs[0].fileName}}</div>
                                    <div class="margin-t-15 flex" v-if="docTaskData">
                                        <template v-if="docTaskData.failureCode === 'FLOW_LACK'">
                                            <div class="polish-status error">{{$t('tools.tools_polishIndex_page_tips_7')}}</div>
                                        </template>
                                        <template v-else>
                                            <div class="polish-status" v-if="['Q', 'P', 'R'].indexOf(docTaskData.status) > -1"><i class="el-icon-loading"></i>{{docTaskData.statusDesc}}</div>
                                            <div class="polish-status warning" v-if="docTaskData.status === 'TE'">{{$t('tools.tools_polishIndex_page_tips_8')}}</div>
                                            <div class="polish-status error" v-if="docTaskData.status === 'F'">{{$t('tools.tools_polishIndex_page_tips_9')}}</div>
                                            <div class="polish-status success" v-if="docTaskData.status === 'S'">{{$t('tools.tools_polishIndex_page_tips_10')}}</div>
                                        </template>
                                    </div>
                                    <div class="margin-t-15">
                                        <el-button round type="primary" size="medium" :loading="btnLoading" @click="createPolishTask" v-if="!docTaskData">{{$t('tools.tools_polishIndex_page_btn_1')}}</el-button>
                                        <template v-if="docTaskData">
                                            <template v-if="docTaskData.failureCode === 'FLOW_LACK'">
                                                <el-button type="primary" size="medium" round @click="payCharPackage">{{$t('tools.tools_polishIndex_page_btn_2')}}</el-button>
                                                <el-button plain round size="medium" icon="el-icon-refresh-left" :loading="btnLoading" @click="restartFilePolish">{{$t('tools.tools_polishIndex_page_btn_3')}}</el-button>
                                            </template>
                                            <template v-else>
                                                <template v-if="['Q', 'P'].indexOf(docTaskData.status) > -1">
                                                    <el-button plain round size="medium" :loading="btnLoading" @click="stopFilePolish" >{{$t('tools.tools_polishIndex_page_btn_4')}}</el-button>
                                                </template>
                                                <!-- <template v-if="docTaskData.status === 'S'">
                                                    <el-button round type="primary" size="medium" :loading="btnLoading" @click="downloadPolishResult">下载</el-button>
                                                </template> -->
                                            </template>
                                        </template>
                                    </div>
                                    <div class="margin-t-10" v-if="docTaskData">
                                        <div class="text-center color-666666 fonts-12" v-if="docTaskData.status === 'TE'">{{$t('tools.tools_polishIndex_page_tips_11')}}</div>
                                        <div class="text-center color-666666 fonts-12" v-if="docTaskData.status === 'R'">{{$t('tools.tools_polishIndex_page_tips_12')}}</div>
                                        <div class="text-center color-666666 fonts-12" v-if="docTaskData.status === 'S'">{{$t('tools.tools_polishIndex_page_tips_13')}}<a href="javascript:;" @click="downloadPolishResult">{{$t('tools.tools_polishIndex_page_tips_14')}}</a></div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="flex margin-t-20">
                        <div class="condition-item">
                            <div class="fonts-12 margin-b-10">{{$t('tools.tools_polishIndex_page_tips_15')}}</div>
                            <el-select size="small" v-model="form.lang" filterable @change="langSelectChangeEvent" :placeholder="$t('tools.tools_polishIndex_page_select_placeholder_2')" :disabled="formDisabled" style="width:100%">
                                <template v-if="polishType === 'text'">
                                    <el-option :label="autoLang ? $t('tools.tools_polishIndex_page_tips_16', {name: LANGUAGE_DICT[autoLang].name}) : $t('tools.tools_polishIndex_page_tips_17')" value="auto" key="auto"></el-option>
                                </template>
                                <template v-for="item in languageList">
                                    <el-option :label="item.name" :value="item.key" :key="item.key"></el-option>
                                </template>
                            </el-select>
                        </div>
                        <div class="condition-item">
                            <div class="fonts-12 margin-b-10">{{$t('tools.tools_polishIndex_page_tips_18')}}</div>
                            <el-select size="small" v-model="form.algorithmType" :placeholder="$t('tools.tools_polishIndex_page_select_placeholder_3')" :disabled="formDisabled" style="width:100%">
                                <template v-for="item in modelList">
                                    <el-option :label="item.name" :value="item.type" :key="item.type">
                                            <span class="flex flex-align-center">
                                                <svg-icon :name="`ie-${item.icon}`" class="margin-r-4 fonts-16"></svg-icon>
                                                {{ item.name }}
                                                <img v-if="item.free" class="margin-l-4" style="height: 14px;" src="../../../../static/images/tools/polish-model-free-label.png" alt="free"/>
                                            </span>
                                    </el-option>
                                </template>
                            </el-select>
                        </div>
                        <div class="condition-item">
                            <div class="fonts-12 margin-b-10">{{$t('tools.tools_polishIndex_page_tips_19')}}</div>
                            <el-select size="small" v-model="form.textType" :placeholder="$t('tools.tools_polishIndex_page_select_placeholder_4')" :disabled="formDisabled" style="width:100%">
                                <template v-for="item in textTypeOptions">
                                    <el-option :label="item.name" :value="item.key" :key="item.key"></el-option>
                                </template>
                            </el-select>
                        </div>
                        <div class="condition-item">
                            <div class="fonts-12 margin-b-10">{{$t('tools.tools_polishIndex_page_tips_20')}}</div>
                            <el-select size="small" v-model="form.style" :placeholder="$t('tools.tools_polishIndex_page_select_placeholder_5')" :disabled="formDisabled" style="width:100%">
                                <template v-for="item in styleOptions">
                                    <el-option :label="item.name" :value="item.key" :key="item.key"></el-option>
                                </template>
                            </el-select>
                        </div>
                        
                    </div>
                    <div class="flex margin-t-20">
                        <div class="flex-1 condition-custom">
                            <div class="fonts-12 margin-b-10">{{$t('tools.tools_polishIndex_page_tips_21')}}</div>
                            <div class="flex flex-flow-wrap">
                                <template v-for="(item, index) in systemStyleOptions">
                                    <div class="padding-r-10 padding-b-5" :key="`system-style-option-${index}`">
                                        <div class="style-label" :disabled="formDisabled" :class="{'active': form.customStyle.includes(item)}" @click="setStyle(item)"><span class="name">{{item}}</span></div>
                                    </div>
                                </template>
                                <template v-for="(name, index) in customStyleOptions">
                                    <div class="padding-r-10 padding-b-5" :key="`custom-style-option-${index}`">
                                        <div class="style-label" :disabled="formDisabled" :class="{'active': form.customStyle.includes(name)}" @click="setStyle(name)"><span class="name">{{ name }}</span><span class="close" @click.stop="removeStyle(name)"><svg-icon name="ib-close"></svg-icon></span></div>
                                    </div>
                                </template>
                                <el-input size="small" v-model="addStyleValue" maxlength="40" show-word-limit @keydown.enter.native="addStyle" @blur="addStyle" :placeholder="$t('tools.tools_polishIndex_page_input_placeholder_1')" :disabled="formDisabled" class="margin-l-5" style="width: 210px;"></el-input>
                            </div>
                        </div>
                    </div>
                    <template v-if="polishType === 'text'">
                        <div class="wapper-block margin-t-20" v-if="resultText">
                            <div class="bg-white radius-16 padding-x-20 padding-b-10">
                                <div class="result-title-content">
                                    <div class="left-content">
                                        <img src="../../../../static/images/tools/polish-brush-img.png" alt="brush" />
                                        <span>{{$t('tools.tools_polishIndex_page_tips_22')}}</span>
                                    </div>
                                    <div>
                                        <el-button plain round size="mini" :loading="formDisabled" @click="createPolishTask"><svg-icon name="ib-brush" v-if="!formDisabled"></svg-icon> {{$t('tools.tools_polishIndex_page_btn_5')}}</el-button>
                                        <el-button  plain round size="mini" :disabled="formDisabled" @click="copyResult"><svg-icon name="ib-copy"></svg-icon> {{$t('tools.tools_polishIndex_page_btn_6')}}</el-button>
                                    </div>
                                </div>
                                <div class="result-content" v-html="resultHtml"></div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <template v-if="polishType === 'document'">
            <div style="width: 900px; margin: 10px auto;" v-if="fileHistory.list.length > 0">
                <!-- <el-button type="text" icon="el-icon-refresh-left" :loading="fileHistory.refreshLoading" @click="initFileHistoryList">刷新列表</el-button> -->
                <el-button type="text" @click="setFileHistoryVisible">
                    <template v-if="fileHistory.listVisible">
                        <svg-icon name="ib-d-arrow-up"></svg-icon> {{$t('tools.tools_polishIndex_page_tips_23')}}
                    </template>
                    <template v-else>
                        <svg-icon name="ib-d-arrow-down"></svg-icon> {{$t('tools.tools_polishIndex_page_tips_24')}}
                    </template>
                </el-button>
            </div>
            <div class="polish-file-history-grid" v-if="fileHistory.listVisible">
                <template v-for="(item, index) in fileHistory.list">
                    <div class="basic-card-item" :key="index">
                        <div class="flex flex-align-center">
                            <div class="type">
                                <svg-icon name="ic-word"></svg-icon>
                            </div>
                            <div class="flex-1 name width-full margin-l-5">
                                <el-tooltip :content="item.title" placement="top-start">
                                    <span>{{item.title}}</span>
                                </el-tooltip>
                            </div>
                        </div>
                        <div class="info margin-t-12">
                            <language-direction :source="item.language"></language-direction>
                            <el-divider direction="vertical" class="margin-x-8"></el-divider>
                            <svg-icon :name="`ie-${item.enginIcon}`" className="fonts-16"></svg-icon>
                            <el-divider direction="vertical" class="margin-x-8"></el-divider>
                            <div class="success" v-if="item.status === 'S'">{{$t('tools.tools_polishIndex_page_tips_25')}}</div> 
                            <div class="fail" v-else-if="item.status === 'F'">{{$t('tools.tools_polishIndex_page_tips_26')}}</div>
                            <el-tooltip placement="top" v-else-if="item.status === 'IT'">
                                <div slot="content">{{item.statusDesc}}，<a href="/account/vip?view=char" target="_blank">{{$t('tools.tools_polishIndex_page_tips_27')}}</a></div>
                                <div class="fail">{{$t('tools.tools_polishIndex_page_tips_28')}}</div>
                            </el-tooltip>
                            <div class="in-progress" v-else><i class="el-icon-loading"></i> {{item.statusDesc}}</div> 
                        </div>
                        <div class="flex flex-align-center margin-t-5">
                            <div class="time flex-1">{{item.date}}</div>
                            <template v-if="['S', 'F', 'IT'].indexOf(item.status) > -1">
                                <el-dropdown placement="bottom" @command="command => handleDropdownEvent(command, item, index)">
                                    <div class="action">
                                        <div class="default">
                                            <svg-icon name="ib-brush"></svg-icon>
                                        </div>
                                        <div class="more">
                                            <svg-icon name="ib-more"></svg-icon>
                                        </div>
                                    </div>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item command="download" v-if="item.status === 'S'">{{$t('tools.tools_polishIndex_page_tips_29')}}</el-dropdown-item>
                                        <el-dropdown-item command="delete" :divided="item.status === 'S'" style="color:#F56C6C;">{{$t('tools.tools_polishIndex_page_tips_30')}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </div>
                    </div>
                </template>
                
            </div>
            <template v-if="fileHistory.listVisible">
                <div class="pagination-content" v-if="fileHistory.list.length > 0 && fileHistory.total > fileHistory.pageSize">
                    <el-pagination 
                        background 
                        @current-change="handleFileHistoryCurrentChange"
                        layout="total, prev, pager, next"
                        :current-page="fileHistory.pageNumber"
                        :page-size="fileHistory.pageSize"
                        :total="fileHistory.total">
                    </el-pagination>
                </div>
            </template>
        </template>
        <alert-dialog ref="alertDialogDom" :message="alertInfo.message" :type="alertInfo.type" :cancelButtonName="alertInfo.cancelButtonName" @confirmEvent="alertInfo.confirmEvent" :buttonType="alertInfo.buttonType"></alert-dialog>
        <!-- <div class="text-center fixed left-124 right-10 bottom-0 zindex-10 padding-y-10">
            <el-button plain round :loading="formDisabled" @click="createPolishTask">{{btnName}}</el-button>
            <el-button type="primary" round @click="copyResult" v-if="resultText">复制结果</el-button>
        </div> -->
    </div>
</template>
<script>
import { mapState } from 'vuex';
import config from '@/utils/config';
import tools from '@/utils/tools';
import { toolsApi, docApi, commonApi } from '@/utils/api';
import UploadOssButton from '@/components/UploadOssButton';
import LanguageDirection from '@/components/LanguageDirection';
import AlertDialog from '@/components/AlertDialog';
export default {
    components: { UploadOssButton, AlertDialog, LanguageDirection, },
    data (){
        return {
            config,
            maxlength: 2000,
            form: {
                text: '',
                algorithmType: 'LANGX',
                lang: 'en',
                textType: '1',
                style: '1',
                customStyle: [this.$t('tools.tools_polishIndex_page_data_form_customStyle_1'), ],
            },
            systemStyleOptions: [
                this.$t('tools.tools_polishIndex_page_data_systemStyleOptions_1'), 
                this.$t('tools.tools_polishIndex_page_data_systemStyleOptions_2'), 
                this.$t('tools.tools_polishIndex_page_data_systemStyleOptions_3'), 
                this.$t('tools.tools_polishIndex_page_data_systemStyleOptions_4'), 
                this.$t('tools.tools_polishIndex_page_data_systemStyleOptions_5'), 
                this.$t('tools.tools_polishIndex_page_data_systemStyleOptions_6'),
            ],
            textInputTimer: null,
            formDisabled: false,
            addStyleValue: '',
            customStyleOptions: [],
            languageList: [],
            taskTimer: null,
            taskDocTimer: null,
            btnName: this.$t('tools.tools_polishIndex_page_data_btnName'),
            resultText: '',
            resultHtml: '',
            autoLang: '',
            polishType: 'text', // text, document
            docs: [],
            fileType: '',
            btnLoading: false,
            modelList: [],
            docTaskData: 0,
            taskId: null,
            alertInfo: {
                message: '',
                type: 'confirm',
                cancelButtonName: this.$t('tools.tools_polishIndex_page_data_alertInfo_cancelButtonName'),
                confirmEvent: ()=>{},
                buttonType: 'primary',
            },
            fileHistory: {
                list: [1,1,1,1,1,1],
                listVisible: true,
                refreshLoading: false,
                pageNumber: 1,
                pageSize: 6,
                total: 0,
            },
            fileHistoryListTimer: null,
            
            /* textTypeOptions: [
                {key: '1', name: '原始长度',},{key: '2', name: '缩写',},{key: '3', name: '扩写',}
            ],
            styleOptions: [
                {key: '1', name: '正式语体',},{key: '2', name: '口语语体',}
            ] */
        }
    },
    computed: {
        ...mapState({
            textTypeOptions: state => state.common.polishTextTypes,
            styleOptions: state => state.common.polishStyleOptions,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        }),
    },
    created(){
        this.languageList = this.LANGUAGE_DICT;/* [
            {key:'en', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_1'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_1')},
            {key:'zh', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_2'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_2')},
            {key:'ru', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_3'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_3')},
            {key:'fr', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_4'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_4')},
            {key:'de', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_5'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_5')},
            {key:'es', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_6'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_6')},
            {key:'pt', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_7'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_7')},
            {key:'ar', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_8'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_8')},
            {key:'ja', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_9'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_9')},
            {key:'ko', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_10'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_10')},
            {key:'id', name: this.$t('tools.tools_polishIndex_page_created_languageList_name_11'), shortName: this.$t('tools.tools_polishIndex_page_created_languageList_shortName_11')},
        ] */;
        this.$store.dispatch('common/initDictDataAction',{type: 'tk-text-type'});
        this.$store.dispatch('common/initDictDataAction',{type: 'tk-polishing-style'});
    },
    methods: {
        createPolishTask() {
            if (this.polishType === 'text') {
                if (!this.form.text){
                    this.$message.error(this.$t('tools.tools_polishIndex_page_method_createPolishTask_message_1'));
                    return;
                }else {
                    if (this.form.text.length < 10) {
                        this.$message.error(this.$t('tools.tools_polishIndex_page_method_createPolishTask_message_2'));
                        return;
                    }
                }
            }
            let postData = {
                ...this.form,
                lang: this.form.lang === 'auto' ? this.autoLang : this.form.lang,
            };
            let url = toolsApi.createTxtPolishTask;
            if(this.polishType === 'document') {
                url = toolsApi.createDocumentPolishTask;
                postData = {
                    ...postData,
                    source: [{
                        name: this.docs[0].fileName,
                        url: this.docs[0].objectName,
                    }]
                }
                this.btnLoading = true;
            }
            this.formDisabled = true;
            this.$ajax.post(url, postData).then(res => {
                if (res.status === 200){
                    this.taskId = res.data.taskId;
                    if(this.polishType === 'text'){
                        this.pollingTxtPolishResult(res.data.taskId);
                        this.taskTimer = setInterval(() => {
                            this.pollingTxtPolishResult(res.data.taskId);
                        }, 1000);
                    }else if(this.polishType === 'document') {
                        /* this.pollingDocumentPolishResult(res.data.taskId);
                        this.taskDocTimer = setInterval(() => {
                            this.pollingDocumentPolishResult(res.data.taskId);
                        }, 1000); */
                        this.closeUploadDocument();
                        this.initFileHistoryList();
                    }
                }
            }).catch(e => {
                this.formDisabled = false;
            }).finally(() => {
                this.btnLoading = false;
            })
        },
        pollingTxtPolishResult(id) {
            let url = this.$ajax.format(toolsApi.pollingPolishResult, {id});
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    let data = res.data;
                    if(['S', 'F'].indexOf(data.status) > -1) {
                        if(data.status === 'S') {
                            this.resultText = data.text || '';
                            this.resultHtml = `<p>${data.revisedText?.replace(/\n/g,'</p><p>')}</p>`;
                        }else if(data.status === 'F' && data.failureCode === 'FLOW_LACK') {
                            if(!this.flowLackMsg) {
                                this.flowLackMsg = this.$message({
                                    showClose: true,
                                    type: 'error',
                                    dangerouslyUseHTMLString: true,
                                    duration: 0,
                                    message: this.$t('tools.tools_polishIndex_page_method_pollingTxtPolishResult_message_1'),
                                });
                            }
                        }
                        this.formDisabled = false;
                        clearInterval(this.taskTimer);
                        this.taskTimer = null;
                    }
                }
            }).catch(e=>{
                this.formDisabled = false;
                clearInterval(this.taskTimer);
                this.taskTimer = null;
            })
        },
        pollingDocumentPolishResult(id) {
            let url = this.$ajax.format(toolsApi.pollingPolishResult, {id});
            this.$ajax.get(url).then(res => {
                if(res.status === 200){
                    this.docTaskData = res.data;
                    if(['S', 'F', 'TE'].indexOf(this.docTaskData.status) > -1){
                        if(this.docTaskData.status === 'S') {
                            if(this.docTaskData && this.docTaskData.docs && this.docTaskData.docs[0].url){
                                window.open(this.docTaskData.docs[0].url, '_self');
                            }
                        }
                        this.formDisabled = false;
                        clearInterval(this.taskDocTimer);
                        this.taskDocTimer = null;
                    }
                }
            }).catch(e=>{
                this.formDisabled = false;
                clearInterval(this.taskDocTimer);
                this.taskDocTimer = null;
            })
        },
        textInputEvent(val){
            let self = this;
            if(this.textInputTimer){
                clearTimeout(this.textInputTimer);
                this.textInputTimer = null;
            }
            if(this.form.lang !== 'auto'){
                return;
            }
            if(val) {
                this.textInputTimer = setTimeout(()=>{
                    self.autoQueryLang();
                }, 500)
            }else {
                this.autoLang = '';
            }
        },
        langSelectChangeEvent(val){
            if(val === 'auto'){
                if(this.form.text) {
                    this.$nextTick(()=>{
                        this.autoQueryLang();
                    });
                }
            }else {
                this.initPolishModel(val);
            }
        },
        autoQueryLang(){
            let url = docApi.getTextLanguage;
            this.$ajax.post(url,{text: this.form.text}).then(res => {
                if(res.status === 200) {
                    this.autoLang = res.data == 'unknown' ? 'zh' : res.data;
                    this.initPolishModel(this.autoLang);
                }
            })
        },
        clearTextInput(){
            this.form.text = '';
            this.resultText = '';
            this.resultHtml = '';
            this.autoLang = '';
            this.btnName = this.$t('tools.tools_polishIndex_page_method_clearTextInput_btnName');
        },
        setStyle(name) {
            let index = this.form.customStyle.indexOf(name);
            if(index > -1){
                this.form.customStyle.splice(index, 1);
            }else {
                this.form.customStyle.push(name);
            }
        },
        removeStyle(name) {
            let index = this.customStyleOptions.indexOf(name);
            this.customStyleOptions.splice(index, 1);
            let _index = this.form.customStyle.indexOf(name);
            if(_index > -1){
                this.form.customStyle.splice(_index, 1);
            }
        },
        addStyle(){
            if(this.addStyleValue){
                if(this.customStyleOptions.includes(this.addStyleValue)){
                    this.$message.warning(this.$t('tools.tools_polishIndex_page_method_addStyle_message_1'));
                }else{
                    this.customStyleOptions.push(this.addStyleValue);
                    this.form.customStyle.push(this.addStyleValue);
                    this.addStyleValue = '';
                }
            }
        },
        copyResult(){
            let self = this;
            this.$copyText(this.resultText).then(function (e) {
                self.$message.success(this.$t('tools.tools_polishIndex_page_method_copyResult_message_1'));
            }, function (e) {
                self.$message.error(this.$t('tools.tools_polishIndex_page_method_copyResult_message_2'));
            })
        },
        enterKeyEvent(event) {
            if((event.ctrlKey || event.metaKey) && event.keyCode == 13){
                this.createPolishTask();
            } 
        },
        uploadFileSuccess() {
            // 文件上传成功
            this.docs = this.$refs.uploadNode.getOssFiles();
            let fileName = this.docs[0].fileName.toLocaleLowerCase();
            this.fileType = fileName.split(".").pop();
            console.log(this.docs, fileName);
        },
        initPolishModel(lang) {
            let url = `${toolsApi.queryPolishModelList}?lang=${lang}`;
            this.$ajax.get(url).then(res => {
                if (res.status === 200) {
                    this.modelList = res.data;
                    this.form.algorithmType = this.modelList[0].type;
                }
            });
        },

        closeUploadDocumentBtnEvent() {
            if (this.docTaskData) {
                if(['Q', 'P'].indexOf(this.docTaskData.status) > -1){
                    this.showAlertDialog({message: this.$t('tools.tools_polishIndex_page_method_closeUploadDocumentBtnEvent_message_1')}, this.closeUploadDocument);
                }else if (['R'].indexOf(this.docTaskData.status) > -1) {
                    this.showAlertDialog({message: this.$t('tools.tools_polishIndex_page_method_closeUploadDocumentBtnEvent_message_2')}, this.closeUploadDocument);
                }else {
                    this.closeUploadDocument();
                }
            }else {
                this.closeUploadDocument();
            }
        },
        closeUploadDocument() {
            this.docs = [];
            this.fileType = '';
            this.docTaskData = null;
            this.formDisabled = false;
            clearInterval(this.taskDocTimer);
            this.taskDocTimer = null;
        },
        stopFilePolishBtnEvent() {
            this.showAlertDialog({message: this.$t('tools.tools_polishIndex_page_method_stopFilePolishBtnEvent_message_1')}, this.stopFilePolish);
        },
        stopFilePolish() {
            this.btnLoading = true;
            let url = toolsApi.stopDocumentPolishTask;
            this.$ajax.post(url, {taskId: this.taskId}).then(res => {
                if(res.status === 200) {
                    this.formDisabled = false;
                    clearInterval(this.taskDocTimer);
                    this.taskDocTimer = null;
                    this.$message.success(this.$t('tools.tools_polishIndex_page_method_stopFilePolish_message_1'));
                    this.docTaskData = {
                        ...this.docTaskData,
                        ...res.data,
                    };
                }
            }).finally(() => {
                this.btnLoading = false;
            })
        },
        payCharPackage() {
            window.open('/account/vip?view=char', '_blank');
        },
        restartFilePolish() {
            this.btnLoading = true;
            let url = toolsApi.restartDocumentPolishTask;
            this.$ajax.post(url, {taskId: this.taskId}).then(res => {
                if(res.status === 200) {
                    this.$message.success(this.$t('tools.tools_polishIndex_page_method_restartFilePolish_message_1'));
                    this.pollingDocumentPolishResult(this.taskId);
                    this.taskDocTimer = setInterval(() => {
                        this.pollingDocumentPolishResult(this.taskId);
                    }, 1000);
                }
            }).finally(() => {
                this.btnLoading = false;
            })
        },
        downloadPolishResult(item) {
            // let url = toolsApi.downloadDocumentPolishFile;
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                title: item.title, 
                metadataIdList: [item.taskId], 
                metadataType: 'TOOLKIT_TP'
            }).then(res => {
                if(res.status === 200) {
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(() => {
                
            })
        },
        showAlertDialog(params, confirmEvent) {
            this.alertInfo = {...this.alertInfo, ...params, confirmEvent};
            this.$refs.alertDialogDom.setShow(true);
        },
        initFileHistoryList() {
            this.fileHistory.refreshLoading = true;
            let url = `${toolsApi.queryFileHistoryList}?pageNo=${this.fileHistory.pageNumber}&pageSize=${this.fileHistory.pageSize}`;
            this.$ajax.get(url).then(res => {
                if (res.status === 200) {
                    this.fileHistory.list = res.data.records;
                    this.fileHistory.total = res.data.total;
                }
            }).finally(() => {
                this.fileHistory.refreshLoading = false;
            })
        },
        deleteTask(item){
            this.taskId = item.taskId;
            this.showAlertDialog({
                message: this.$t('tools.tools_polishIndex_page_method_deleteTask_message_1'),
                buttonType: 'danger',
                cancelButtonName: this.$t('tools.tools_polishIndex_page_method_deleteTask_cancelButtonName_1'),
            }, ()=>(this.deleteTaskEvent(item)));
        },
        deleteTaskEvent(item) {
            let url = this.$ajax.format(toolsApi.deleteFilePolishTask, {id: item.taskId});
            this.$ajax.delete(url).then(res => {
                if(res.status === 200) {
                    this.$message.success(this.$t('tools.tools_polishIndex_page_method_deleteTaskEvent_message_1'));
                    this.initFileHistoryList();
                }
            })
        },
        
        handleFileHistoryCurrentChange(val) {
            this.fileHistory.pageNumber = val;
            this.initFileHistoryList();
        },
        handleDropdownEvent(command, item, index) {
            switch (command) {
                case 'download':
                    this.downloadPolishResult(item);
                    break;
                case 'delete':
                    this.deleteTask(item);
                    break;
            }
        },
        setFileHistoryVisible() {
            this.fileHistory.listVisible = !this.fileHistory.listVisible;
            /* if (this.fileHistory.listVisible) {
                this.initFileHistoryList();
            } */
        },
    },
    beforeRouteLeave: function(to, from , next){
        if(this.taskDocTimer){
            // next(false);
            this.$confirm(this.$t('tools.tools_polishIndex_page_method_beforeRouteLeave_message_1'), this.$t('tools.tools_polishIndex_page_method_beforeRouteLeave_message_2'), {
                confirmButtonText: this.$t('tools.tools_polishIndex_page_method_beforeRouteLeave_confirmButtonText'),
                cancelButtonText: this.$t('tools.tools_polishIndex_page_method_beforeRouteLeave_cancelButtonText'),
                type: 'warning'
            }).then(()=>{
                next();
            }).catch(() => {
                this.$store.dispatch("common/updateRefreshHeader");
            })
        }else{
            next();
        }
    },
    mounted(){
        this.initPolishModel(this.form.lang);
        this.initFileHistoryList();
        this.fileHistoryListTimer = setInterval(()=>{
            this.initFileHistoryList();
        }, 1000 * 10);
        this.$shortcut.bind('ctrl+enter,⌘+enter', ()=>{
            this.createPolishTask();
        });
    },
    beforeDestroy(){
        clearInterval(this.taskTimer);
        this.taskTimer = null;
        clearInterval(this.taskDocTimer);
        this.taskDocTimer = null;
        clearInterval(this.fileHistoryListTimer);
        this.fileHistoryListTimer = null;
        if(this.flowLackMsg) {
            this.flowLackMsg.close();
        }
    }
}
</script>
<style lang="scss" scoped>
.polish-index-page {
    position: relative;
    height: 100%;
    background-image: url("../../../../static/images/tools/polish-bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: auto;
    .polish-wapper {
        // min-height: 1000px;
        width: 900px;
        margin: 20px auto 0 auto;
        background: rgba(255, 255, 255, 0.5);
        border: 0.669748px solid rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(20.0924px);
        border-radius: 20px;
    }
    .polish-main{
        // min-height: 900px;
        width: 860px;
        margin: 20px auto;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid #FFFFFF;
        box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(10px);
        border-radius: 20px;
        .title-content{
            height: 52px;
            box-sizing: border-box;
            border-bottom: 1px solid #0000001A;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: 600;
            img{
                width: 33px;
                height: 32px;
                margin-right: 8px;
            }
        }
        .polish-tabs {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
            .item {
                height: 36px;
                padding: 0 20px; 
                display: flex;
                align-items: center;
                font-size: 14px;
                background-color: #FFFFFF;
                border: 1px solid #00000033;
                border-radius: 50px;
                box-sizing: border-box;
                color: #000000;
                margin-left: 10px;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
                cursor: pointer;
                &:first-child {
                    margin-left: 0;
                }
                &.active {
                    background-color: #E5E5E5;
                    border-color: #E5E5E5; // #7DD6B3
                    color: #000000;
                    cursor: default;
                }
                svg {
                    font-size: 16px;
                    margin-right: 4px;
                }
            }
        }
        .polish-content {
            padding: 20px;
            ::v-deep .el-textarea {
                &.is-disabled .el-textarea__inner {
                    background-color: #ffffff;
                }
            }
            .condition-item {
                width: 120px;
                padding-right: 10px;
            }
            
            .condition-custom {
                ::v-deep .el-input {
                    .el-input__inner{
                        // height: 24px;
                        // font-size: 12px;
                        border-radius: 20px;
                        border-style: dashed;
                        padding-right: 45px;
                    }
                }
            }
            .model-wapper {
                margin-right: 10px;
                .model-list {
                    display: flex;
                    .item {
                        height: 26px;
                        margin-left: 10px;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 15px;
                        background-color: #ffffff;
                        border-radius: 4px;
                        box-sizing: border-box;
                        border: 1px solid #0000001A;
                        cursor: pointer;
                        &:first-child {
                            margin-left: 0;
                        }
                        &.active {
                            border-color: #1AC49C;
                            cursor: default;
                        }
                    }
                }
            }
            .polish-document-wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                .file-type {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-color: #F8F8F8;
                    font-size: 33px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .polish-status {
                    height: 24px;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    background-color: #2979FF33;
                    color: #2979FF;
                    border-radius: 20px;
                    &.warning {
                        background-color: #FF990033;
                        color: #FF9900;
                    }
                    &.error {
                        background-color: #F56C6C33;
                        color: #F56C6C;
                    }
                    &.success {
                        background-color: #00B67033;
                        color: #00B670;
                    }
                    i{
                        margin-right: 4px;
                    }
                }
                .close-btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: #666666;
                    padding: 14px;
                    cursor: pointer;
                    &:hover {
                        color: #000000;
                    }
                }
            }
        }
        .result-title-content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 52px;
            .left-content{
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 15px;
                img{
                    width: 33px;
                    height: 32px;
                    margin-right: 12px;
                }
            }
        }
        .result-content {
            margin-top: 10px;
            font-size: 14px;
            line-height: 22px;
            height: 162px;
            // max-height: 240px;
            overflow-y: auto;
            ::v-deep .diff-highlight {
                background-color: #D4E4FF;
                // font-weight: 600;
            }
            
        }
    }
    .wapper-block {
        background: linear-gradient(180deg, rgba(215, 245, 232, 0.6) 0%, rgba(215, 233, 245, 0) 100%);
        backdrop-filter: blur(20.0924px);
        border-radius: 20px;
        padding: 15px;
        
    }
    .style-label {
        padding: 0 10px;
        height: 32px;
        font-size: 13px;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-sizing: border-box;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
        &:first-child{
            margin-left: 0;
        }
        &:hover{
            .name{
                color:#000;
            }
        }
        
        &.active {
            border-color: #00B670;
            .name{
                color: #00B670;
            }
        }
        .name {
            color: #999999;
        }
        .close {
            color: #999999;
            margin-left: 10px;
            font-size: 14px;
            &:hover{
                color: #000;
            }
        }
    }
    .text-input {
        margin-top: 10px;
        ::v-deep .el-textarea__inner{
            padding: 0;
            border: none;
        }
    }
    .copy-btn {
        width: 60px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        border-radius: 32px;
        font-size: 12px;
        cursor: pointer;
    }
    [disabled],:disabled{
        cursor: not-allowed;
        pointer-events: none;
        opacity: .5;
    }
    .send-btn {
        font-size: 20px;
        padding: 5px;
    }
    .polish-file-history-grid {
        width: 900px;
        margin: 0px auto;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        .in-progress {
            color: #2979FF;
        }
        .fail {
            color: #F56C6C;
        }
        .success {
            color: #00B670;
        }
    }
}
</style>
